<div class="common-layout {{ 'is-' + selectedHeaderColor }}" 
    [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }"
>
    <app-header [showRibbon]="showRibbon" [patientUserId]="patientUserId"></app-header>
    
    <app-sidenav [showRibbon]="showRibbon"></app-sidenav>
    <div class="page-container">
          <div *ngIf="showFloatingCall.status" class="col-md-12 col-12 floating-main">
            <div class="col-md-4 col-12 floating-sub">
                <div class="row text-center p-v-5 floating-info-area">
                    <div class="col-md-4 col-12">
                        {{showFloatingCall.patientName}}
                    </div>
                    <div class="col-md-4 col-12">
                        {{showFloatingCall.dob}}
                    </div>
                    <div class="col-md-4 col-12">
                        {{showFloatingCall.physician}}
                    </div>    
                </div>             
        
                <div class="row text-center">
                    <nz-button-group class="col-12 floating-button-group" nzSize="default" nzDanger>
                        <button class="col-6" nz-button [nzType]="isVoiceMuted ? 'primary' : 'default'"  (click)="muteVoicecall()" nzDanger><i nz-icon [nzType]="isVoiceMuted ? 'audio-muted' : 'audio'" nzTheme="outline"></i>Mute</button>
                        <button class="col-6" nz-button nzType="primary"  (click)="disconnectVoice()" nzDanger><i nz-icon nzType="phone" nzTheme="outline"></i>End Call</button>
                    </nz-button-group>            
                </div>
            </div>
          </div>

        <div class="main-content" [ngStyle]="{'padding': showRibbon ? 'calc(70px + 32px + 25px) 25px 25px 25px' : 'calc(70px + 25px) 25px 25px 25px'}">
            <!-- <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
                <nz-breadcrumb nzSeparator=">">
                    <i class="m-r-5 text-gray" nz-icon nzType="home"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a [routerLink]="breadcrumb.url">
                            {{ breadcrumb.label }}
                        </a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
            </div> -->
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>