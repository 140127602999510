import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeStageService {

  constructor() { }

  getThemeStagesBp(stage: number = null): any {
    const retAr: any = [
      { color: 'red', icon: 'arrow-up', desc: 'Hypertensive Crisis' },
      { color: 'red', icon: 'arrow-up', desc: 'Hypertension Stage 2' },
      { color: 'red', icon: 'arrow-up', desc: 'Hypertension Stage 1' },
      { color: 'orange', icon: 'arrow-up', desc: 'Elevated' },
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'cyan', icon: 'arrow-down', desc: 'Hypotensive' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesBg(stage: number = null): any {
    const retAr: any = [
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'orange', icon: 'arrow-down', desc: 'Hypoglycemic' },
      { color: 'red', icon: 'arrow-up', desc: 'Hyperglycemic' }
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesHeartRate(data: any = null): any {
    // formula derived from this https://www.heart.org/en/healthy-living/fitness/fitness-basics/target-heart-rates
    const age = null === data || data.age === null ? 50 : data.age;
    const rate = null === data || data.rate === null ? 50 : data.rate;
    const moderateLow = (220 - age) * 0.5;
    const moderateHigh = (220 - age) * 0.85;
    const retAr: any = [
      { color: 'orange', icon: 'arrow-down', desc: 'Slow' },
      { color: 'green', icon: 'check', desc: 'Moderate' },
      { color: 'red', icon: 'arrow-up', desc: 'Fast' }
    ];
    let index = 0;
    if (rate > moderateHigh) {
      index = 2;
    } else if (rate >= moderateLow && data.rate <= moderateHigh) {
      index = 1;
    } else if (rate < moderateLow) {
      index = 0;
    }
    return null === data ? retAr : retAr[index];
  }

  //New formula to calculate stage as per the user's heart rate. <50 = low, >=50 & <=110 = normal, >110 = High
  getThemeStagesHeartRateInfo(data: any = null): any {
    const rate = null === data || data.rate === null ? 55 : data.rate;
    const retAr: any = [
      { color: 'orange', icon: 'arrow-down', desc: 'Low' },
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'red', icon: 'arrow-up', desc: 'High' }
    ];
    let index = 0;
    if (rate > 120) {
      index = 2;
    } else if (rate >= 50 && data.rate <= 120) {
      index = 1;
    } else if (rate < 50  ) {
      index = 0;
    }
    return null === data ? retAr : retAr[index];
  }

  getThemeStagesWeight(data: any = null): any {
    // formula derived from this https://www.heart.org/en/healthy-living/healthy-eating/losing-weight/bmi-in-adults
    const height = null === data || data.height === null || data.height === 0 ? 66 : data.height / 2.54;
    const weight = null === data || data.weight === null ? 100 : data.weight;
    const bmi = ((703 * weight) / height) / height;
    const retAr: any = [
      { color: 'orange', icon: 'arrow-down', desc: 'Underweight' },
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'red', icon: 'arrow-up', desc: 'Overweight' },
      { color: 'red', icon: 'arrow-up', desc: 'Obese' }
    ];
    let index = 0;
    if (bmi >= 30) {
      index = 3;
    } else if (bmi >= 25 && bmi < 30) {
      index = 2;
    } else if (bmi >= 18.5 && bmi < 25) {
      index = 1;
    } else if (bmi < 18.5) {
      index = 0;
    }
    return null === data ? retAr : retAr[index];
  }

  getThemeStagesTotalCholesterol(stage: number = null): any {
    const retAr: any = [
      { color: 'red', icon: 'arrow-up', desc: 'High' },
      { color: 'orange', icon: 'arrow-up', desc: 'BorderLine' },
      { color: 'green', icon: 'check', desc: 'Good' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesHdlCholesterol(stage: number = null): any {
    const retAr: any = [
      { color: 'red', icon: 'arrow-down', desc: 'Low' },
      { color: 'orange', icon: 'arrow-down', desc: 'BorderLine' },
      { color: 'green', icon: 'check', desc: 'Good' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesLdlCholesterol(stage: any = null): any {
    const retAr: any = [
      { color: 'red', icon: 'arrow-up', desc: 'High' },
      { color: 'orange', icon: 'arrow-up', desc: 'BorderLine' },
      { color: 'green', icon: 'check', desc: 'Good' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStageTriglyceridesCholesterol(stage: any = null): any {
    const retAr: any = [
      { color: 'red', icon: 'arrow-up', desc: 'High' },
      { color: 'orange', icon: 'arrow-up', desc: 'BorderLine' },
      { color: 'green', icon: 'check', desc: 'Good' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getSleepStages(stage: number): any {
    const retAr: any = [
      { desc: 'Awake' },
      { desc: 'Sleep' },
      { desc: 'Out of Bed' },
      { desc: 'Light Sleep' },
      { desc: 'Deep Sleep' },
      { desc: 'REM' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getTaskStage(stage: number): any {
    const retAr = [
      { color: 'green', icon: 'arrow-down', desc: 'Low' },
      { color: 'orange', icon: 'minus', desc: 'Medium' },
      { color: 'red', icon: 'arrow-up', desc: 'High' },
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesSPO2(stage: number = null): any {
    const retAr: any = [
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'red', icon: 'arrow-down', desc: 'Below Normal' }
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }

  getThemeStagesTemperature(stage: number = null): any {
    const retAr: any = [
      { color: 'green', icon: 'check', desc: 'Normal' },
      { color: 'red', icon: 'arrow-up', desc: 'Fever' },
      { color: 'blue', icon: 'arrow-down', desc: 'Cold' }
    ];
    return null === stage ? retAr : retAr[stage - 1];
  }
}
