import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {

  private apiEndpoints = API_ENDPOINTS;

  constructor(private http: HttpClient
    ) { }

    sendMessage(patientUserId,msg): Observable<any> {
      return this.http.post<any>(this.apiEndpoints.provider.patients.chat.send.replace('{id}', patientUserId), msg);
    }

    listMessage(patientUserId,data): Observable<any> {
      let params = new HttpParams()
      .append('pageSize', `${data}`);
      return this.http.get<any>(this.apiEndpoints.provider.patients.chat.list.replace('{id}', patientUserId), {params});
    }

    unseenMessage(patientUserId): Observable<any> {
      return this.http.post<any>(this.apiEndpoints.provider.patients.chat.unseenMessage.replace('{id}', patientUserId), {});
    }

    getUnseenMessageList(): Observable<any> {
      return this.http.post(this.apiEndpoints.provider.patients.chat.unseenMessageList,{});
    }


}
