import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Globals {
  bulkMessagePermission = false;
  taskManagerPermission = false;
  requestCount = 0;
  waitingRoomCount = 0;
  scheduledCount = 0;
  alertCount = 0;
  assignedTaskCount = 0;
  billableMinutes = 0;
  todayBillableMinutes = 0;
  requestNotificationLists = [];
  roomNotificationLists = [];
  alertNotificationList = [];
  assignedTasks = [];
  waitingRoomLogs = [];
  videoCallLogs = [];
  scheduledLogs = [];
  missedCallLogs = [];
  missedCallCount = 0;
  alertLogs = [];
  assignedTasksNotificationList = [];
  videoCallChannel = 'video-call-channel';
  activeVideoCall = {};
  activeBlock = null;

  biometricsUpdateText = 'Updated user record!';
  userPresenceNew = new Map();
  alert_count = new Map();

  providerPresence = [];
  patientListUnderReview = [];
  patientListUnderReviewNumber = 0;
  patientUnderReview = 0;

  voiceCallStatus = 'connecting...';
  voiceCallPatientId: number;
  voiceCallUserId: number;

  stagingVoiceCallStatus = 'connecting...';
  stagingVoiceCallPatientId: number;
  lastUrl = '';

  censusOrPending = '';
  globalsPageIndex = 1;
  globalsCurrentSort = null;
  globalsSortField = null;
  globalsSortOrder = null;
  globalsFilter = [];

  unseenMessageCount = 0;
  unseenMessageList = [];

  patientUnderReviewList = new Map();
  // Patient Notes templates
  defaultNotes = [
    'Biometric review complete, continuing to monitor.',
    'Engagement call with patient, requested daily blood pressure monitoring with the TupeloLife BLOOD PRESSURE cuff for provider trending and improved blood pressure control.',
    'Engagement call with patient, requested daily blood glucose monitoring with the TupeloLife BLOOD GLUCOSE MONITOR for provider trending and improved blood glucose control.',
    'Engagement call, left message for return call.'
  ];

  bpNotes = [
    'Reviewed. ',
    'Reviewed BP reading, >180 systolic. ',
    'Reviewed BP reading, <90 systolic. ',
    'Reviewed BP reading, >120 diastolic. ',
    'Reviewed BP reading, <45 diastolic. ',
    'Reviewed heart rate, >120. ',
    'Reviewed heart rate, <50. '
  ];

  bgNotes = [
    'Reviewed. ',
    'Reviewed blood glucose, >325. ',
    'Reviewed blood glucose, <60. '
  ];

  pulseNotes = [
    'Reviewed. ',
    'Reviewed heart rate, >120. ',
    'Reviewed heart rate, <50. '
  ];



  spo2Notes = [
    'Reviewed SpO2 results, within the provider threshold.',
    'Reviewed SpO2 results, < 90. Critical alert sent to provider.',
    'Reviewed low SpO2 results.  Critical alert sent to provider.',
    'Called patient to review low SpO2 readings.  Denies symptoms of confusion/inability to concentrate, shakiness, etc.',
    'Patient instructed to eat and recheck SpO2 in 30 minutes.',
    'Call placed to provider to discuss out of range SpO2.',
    'Called patient to review SpO2 readings, assessed symptoms and/or barriers, and provided education.',
    'Instructed patient to seek emergency services for critical SpO2 reading.',
    'Educated on diet and exercise.',
    'Called, left voicemail for return call to review SpO2 readings.'
  ];

  temperatureNotes = [
    'Reviewed temperature results, within the provider threshold.',
    'Reviewed temperature results, > 100. Critical alert sent to provider.',
    'Called patient to review elevated temperature readings.  Denies symptoms of frequent urination, thirst, headache, blurry vision, etc.',
    'Reviewed low temperature results.  Critical alert sent to provider.',
    'Called patient to review low temperature readings.  Denies symptoms of confusion/inability to concentrate, shakiness, etc.',
    'Patient instructed to eat and recheck temperature in 30 minutes.',
    'Call placed to provider to discuss out of range temperature.',
    'Called patient to review temperature readings, assessed symptoms and/or barriers, and provided education.',
    'Instructed patient to seek emergency services for critical temperature reading.',
    'Educated on diet and exercise.',
    'Called, left voicemail for return call to review temperature readings.'
  ];




  bpNotesV2 =
    {
      'CRITICAL READINGS': [
        'Critical <strong>alert sent</strong> to provider. ',
        'Called patient to review <strong>ELEVATED BP</strong> and associated <strong>symptoms</strong> (headache, nausea, blurry vision, chest pain, shortness of breath, slurred speech, confusion). ',
        'Called patient to review <strong>LOW BP</strong> and associated <strong>symptoms</strong> (weakness, dizziness, lightheadedness, nausea/vomiting, cool clammy skin, chest pain, shortness of breath). ',
        '<strong>Notified</strong> provider via <strong>EHR/telephone</strong> of critical alert. ',
        '<strong>Directed</strong> patient to contact <strong>provider</strong> and/or <strong>call 9-1-1</strong> for critical reading. ',
        'Directed to <strong>recheck</strong> blood pressure in <strong>5 minutes</strong> and reinforced proper positioning for accurate blood pressure readings. ',
      ],
      'EDUCATION & INTERVENTIONS': [
        'Directed patient to check BP <strong>3 times daily</strong> to improve understanding of blood pressure related to medication adherence and effectiveness. ',
        'Educated on benefits of <strong>Advanced Care Planning</strong> and end-of-life decisions. ',
        'Directed patient to schedule <strong>appointment</strong> with provider to discuss blood pressure related to BP readings. ',
        'Educated on <strong>cholesterol</strong> as it relates to high blood pressure and to support know your numbers. ',
        'Educated on proper use of BP <strong>cuff, monitor, positioning,</strong> and waiting 30 minutes after smoking, exercise, caffeine, or alcohol, for accurate blood pressure readings. ',
        'Educated on benefits of <strong>DAILY</strong> blood pressure <strong>monitoring</strong>. ',
        'Educated on symptomatic readings, the importance of <strong>early intervention</strong>, when to call provider, and seek emergency medical assistance. ',
        'Completed <strong>EHR review</strong>. ',
        'Educated patient on <strong>exacerbation avoidance</strong> to avoid ER visits and hospitalizations. ',
        'Educated on <strong>exercise</strong> and increasing activity to help lower blood pressure. ',
        'Educated on <strong>fall</strong> precautions, changing positions slowly, and safety. ',
        '<strong>Faxed</strong> biometric readings to provider or specialist. ',
        'Educated on setting <strong>goals</strong>. ',
        'Educated on the <strong>health threats</strong> of hypertension including stroke, heart attack, heart failure, kidney disease, vision loss, and sexual dysfunction. ',
        'Educated on the importance of proper <strong>nutrition</strong> to include DASH Diet, limiting <strong>sodium</strong>, processed foods, and decreasing caffeine and/or alcohol intake. ',
        'Educated on RPM <strong>patient portal</strong> to utilize tools to improve self-management. ',
        'Educated on benefits of <strong>self-management</strong> to promote health and long-term BP control. ',
        'Educated on <strong>smoking</strong> cessation, risks and benefits. ',
        'Educated on blood pressure <strong>stages</strong> to improve understanding of "<strong>Know Your Numbers</strong>." ',
        'Sent <strong>Text</strong> message to patient. ',
        'Educated on <strong>warning</strong> signs of a <strong>heart attack</strong>, chest pain or discomfort, lightheadedness, nausea, vomiting, jaw, neck or back pain, arm or shoulder pain, and/or shortness of breath. ',
        'Educated patient on <strong>warning</strong> signs of a <strong>stroke</strong>: FAST (Facial droop, Arm weakness, Speech difficulty, Time to call 9-1-1). ',
        'Family/patient/caregiver <strong>conference</strong> to collaborate, coordinate care, identify needs/barriers and provide care updates. ',
        'Family/patient/caregiver <strong>notified</strong> of changes in care plan/plan of care. ',
        '<strong>Faxed</strong> biometric readings to provider or specialist. ',
        '<strong>NURSE referral</strong> for additional education and review. ',
        '<strong>Provider conference</strong> to collaborate and coordinate patient care. ',
        '<strong>Provider notified</strong> via EHR/telephone of critical alert. ',
        '<strong>Provider visit:</strong> Called patient post-visit to review and support provider\'s plan of care. ',
        '<strong>Voicemail left for return call</strong>. ',
        '<strong>Voicemail unavailable.</strong> ',
      ],

      MEDICATION: [
        'Educated on the importance of medication <strong>adherence</strong> to manage hypertension. ',
        'Educated patient to visit with <strong>pharmacist</strong> to review all medications, side effects, and barriers. ',
        'Educated on strategies to improve medication adherence and explore use of <strong>REMINDER</strong> techniques. ',
        'Completed targeted medication <strong>review</strong> for blood pressure medications and discussed possible barriers. ',
        'Educated on BP medications, including common <strong>side effects</strong> and safety. ',
      ],
      RESOURCES: [
        'Sent <strong>AHA video</strong> link sent to patient via email and/or text message.  https://www.youtube.com/watch?v=rAwliNWe1bI ',
        'Referred patient to Aunt Bertha findhelp.org ',
        'Referred to 211/DHHS. ',
      ],
      ENGAGEMENT: [
        'Program <strong>non-compliance</strong> outreach to explore missed <strong>16 days</strong> of readings per month. Called and reviewed RPM Program and biometric reading requirements for program participation to secure 16 days of readings per month to help manage blood pressure. ',
        'Educated patient on <strong>RPM Program</strong> and biometric reading requirements for program participation to secure 16 days of readings per month to help manage blood pressure. ',
        'Welcome call. Called patient to assist with successful blood pressure monitor set-up. Patient verbally agreed to the RPM program and text messaging. Educated patient on proper technique to check blood pressure, sent American Heart Association video via text. Reminded patient of program requirements to check blood pressure daily. Requested patient check blood pressure 3 to 4 times daily (prior to taking medication, 1 to 3 hours after taking medication, late afternoon, and before bed if possible) for the first 1 to 2 weeks to provide feedback to PCP. Instructed patient to expect calls for critical readings from care team. Provided care team\'s contact information. TupeloLife care team monitors biometric results, Monday through Friday between 8 AM and 5 PM CST for after-hour concerns please call your primary care provider or 9-1-1 as needed. ',
      ],
      READINGS: [
        'Reviewed ',
        'Reviewed BP reading, >180 systolic. ',
        'Reviewed BP reading, <90 systolic. ',
        'Reviewed BP reading, >120 diastolic. ',
        'Reviewed BP reading, <45 diastolic. ',
        'Reviewed heart rate, >120. ',
        'Reviewed heart rate, <50. ',
      ]
    };

    pulseNotesV2 = {
      'CRITICAL READINGS': [
        'Critical <strong>alert sent</strong> to provider. ',
        'Called patient to review <strong>LOW heart rate</strong> and associated <strong>symptoms</strong> (chest pain, shortness of breath, cool clammy skin, confusion, dizziness, fatigue, fainting). Seek immediate help if you are feeling any of these symptoms. ',
        'Called patient to review <strong>ELEVATED heart rate</strong> and associated <strong>symptoms</strong> (chest pain/tightness, shortness of breath, cool clammy skin, dizziness, fainting, palpitations or fluttering in chest). Seek immediate help if you are feeling any of these symptoms. ',
        '<strong>Notified</strong> provider via <strong>EHR/telephone</strong> of critical alert. ',
        '<strong>Directed</strong> patient to contact <strong>provider</strong>, or call <strong>9-1-1</strong> for critical alert. ',
        'Directed to <strong>recheck</strong> heart rate in <strong>5 minutes</strong> and reminded of proper positioning. ',
      ],
      'EDUCATION & INTERVENTIONS': [
        'Directed patient to schedule <strong>appointment</strong> with provider to discuss heart rate related to current biometric reading. ',
        '<strong>Directed</strong> patient to contact <strong>provider</strong> and/or call <strong>9-1-1</strong> for critical reading. ',
        'Educated on benefits of <strong>Advanced Care Planning</strong> and end-of-life decisions. ',
        'Educated on proper use of BP <strong>cuff, monitor, positioning</strong>, and waiting 30 minutes after smoking, exercise, caffeine, or alcohol, for accurate readings. ',
        'Educated on benefits of <strong>DAILY monitoring</strong>. ',
        'Educated on symptomatic readings, the importance of <strong>early intervention</strong>, when to call provider, and seek emergency medical assistance. ',
        'Completed <strong>EHR review</strong>. ',
        'Educated patient on <strong>exacerbation avoidance</strong> to avoid ER visits and hospitalizations. ',
        'Educated on <strong>exercise</strong> and increasing activity. ',
        'Educated on <strong>fall</strong> precautions, changing positions slowly, and safety. ',
        '<strong>Faxed</strong> biometric readings to provider or specialist. ',
        'Educated on setting <strong>goals</strong>. ',
        'Educated on normal heart rate (60 to 100) to improve understanding of "<strong>Know Your Numbers</strong>." ',
        'Directed patient to schedule <strong>PCP appointment</strong> to discuss heart rate related to current biometric readings. ',
        'Educated on RPM <strong>patient portal</strong> to utilize tools to improve self-management. ',
        'Educated on benefits of <strong>self-management</strong> to promote health and long-term quality of life. ',
        'Sent <strong>Text</strong> message to patient. ',
        'Educated on <strong>warning</strong> signs of a <strong>heart attack</strong>, chest pain or discomfort, lightheadedness, nausea, vomiting, jaw, neck or back pain, arm or shoulder pain, and/or shortness of breath. ',
        'Educated patient on <strong>warning</strong> signs of a <strong>stroke</strong>: FAST (Facial droop, Arm weakness, Speech difficulty, Time to call 9-1-1). ',
        '<strong>Family</strong>/patient/caregiver <strong>conference</strong> to collaborate, coordinate care, identify needs/barriers and provide care updates. ',
        '<strong>Family</strong>/patient/caregiver <strong>notified</strong> of changes in care plan/plan of care. ',
        '<strong>NURSE referral</strong> for additional education and review. ',
        '<strong>Provider conference</strong> to collaborate and coordinate patient care. ',
        '<strong>Provider notified</strong> via EHR/telephone of critical alert. ',
        '<strong>Provider visit</strong>: Called patient post-visit to review and support provider\'s plan of care. ',
        '<strong>Voicemail left</strong> for return call. ',
        '<strong>Voicemail unavailable</strong>. ',
      ],
      MEDICATION: [
        'Educated on the importance of medication <strong>adherence</strong> to manage chronic conditions. ',
        'Educated patient to visit with <strong>pharmacist</strong> to review all medications, side effects, and barriers. ',
        'Educated on strategies to improve medication adherence and explored use of <strong>REMINDER</strong> techniques. ',
        'Completed targeted medication <strong>review</strong> and discussed possible barriers. ',
        'Educated on medications, including common <strong>side effects</strong> and safety.  ',
      ],
      RESOURCES: [
        'Referred to 211/DHHS. ',
        'Referred patient to Aunt Bertha findhelp.org ',
        'Sent <strong>AHA video</strong> link sent to patient via email and/or text message.  https://www.youtube.com/watch?v=rAwliNWe1bI ',
      ],
      ENGAGEMENT: [
        'Program <strong>non-compliance</strong> outreach to explore missed <strong>16 days</strong> of readings per month. Called and reviewed RPM Program and biometric reading requirements for program participation to secure 16 days of readings per month. ',
        'Educated patient on <strong>RPM Program</strong> and biometric reading requirements for program participation to secure 16 days of readings per month to help manage blood pressure. ',
      ],
      READINGS: [
        'Reviewed ',
        'Reviewed heart rate, >120. ',
        'Reviewed heart rate, <50. ',
      ],
    };
    bgNotesV2 = {
      'CRITICAL READINGS': [
        'Critical <strong>alert sent</strong> to provider. ',
        'Called patient to review <strong>LOW BG, <60</strong> and associated <strong>symptoms</strong> and/or barriers (shakiness, nervous/anxious, irritable, cool clammy skin, confusion, inability to concentrate, hunger, nausea, sleepy). ',
        'Denies <strong>LOW</strong> blood glucose <strong>symptoms</strong> of shakiness, irritability, confusion, elevated heart rate, and hunger. ',
        'Called patient to review <strong>HIGH BG, > 325</strong> and associated <strong>symptoms</strong> and/or barriers (frequent urination, thirst, headache, blurry vision). ',
        'Denies <strong>HIGH</strong> BG <strong>symptoms</strong> of frequent urination, thirst, headache, blurry vision. ',
        '<strong>Directed</strong> patient to contact <strong>provider</strong>, or call <strong>9-1-1</strong> for critical alert. ',
        '<strong>Directed</strong> patient to <strong>eat</strong> and <strong>recheck</strong> blood glucose in <strong>15 minutes</strong> (4 oz of juice or regular soda, glucose tablets, 1 tablespoon of sugar or honey, or hard candies). Repeat these steps every 15 minutes until blood glucose reaches at least 60 mg/dL. Once blood glucose is back to normal, eat a meal or snack. ',
        '<strong>Notified</strong> provider via <strong>EHR/telephone</strong> of critical alert. '
      ],
      'EDUCATION & INTERVENTIONS': [
        'Educated on benefits of <strong>Advanced Care Planning</strong> and end-of-life decisions. ',
        'Educated patient on <strong>Alcohol</strong> and diabetes. Drinking alcohol combined with medications used to treat DM can result in LOW BG. (1 drink a day for women and 2 drinks a day for men = 5 oz wine OR 12 oz beer OR 1.5 oz of 80 proof spirits.) ',
        'Educated on proper use of <strong>BG monitor</strong>. ',
        'Directed patient on <strong>critical reading</strong>, contacting provider, or calling 9-1-1. ',
        'Educated on benefits of <strong>DAILY</strong> BG <strong>monitoring</strong>. ',
        'Educated on critical BG readings, the importance of <strong>early intervention</strong>, when to call provider, and seek emergency medical assistance. ',
        'Completed <strong>EHR review</strong>. ',
        'Educated on <strong>exercise</strong> and increasing activity to help lower BG. ',
        'Educated on <strong>fall</strong> precautions, changing positions slowly, and safety. ',
        '<strong>Faxed</strong> biometric readings to provider or specialist. ',
        'Educated patient on <strong>Foot</strong> care. (Washing, drying, moisturizing, nail care, inspecting with mirror, properly fitting shoes, and avoiding walking barefoot.) ',
        'Educated on setting <strong>goals</strong>. ',
        'Educated on the <strong>health threats</strong> of diabetes (CAD, heart attack, stroke, amputations, and kidney disease). ',
        'Educated on BG values, daily readings, and A1c, to improve understanding of "<strong>Know Your Numbers</strong>." ',
        'Educated on diet and the importance of proper <strong>nutrition</strong> to control BG. ',
        'Educated on <strong>patient portal</strong> to utilize tools to improve self-management. ',
        'Directed patient to schedule <strong>PCP appointment</strong> to discuss BG related to current biometric readings. ',
        'Educated patient on how to <strong>PREVENT LOW BG</strong>, good DM management, frequent BG monitoring, early warning signs, and having an emergency plan in place. Check BG before and after meals, before bed, after exercise, in the middle of the night, with increased frequency during illness, or with medication changes. ',
        'Educated on benefits of <strong>self-management</strong> to improve health and long-term quality of life. ',
        'Educated on <strong>smoking</strong> cessation, risks, and benefits. ',
        'Sent <strong>Text</strong> message to patient. ',
        'Family/patient/caregiver <strong>conference</strong> to collaborate, coordinate care, identify needs/barriers, and provide care updates. ',
        'Family/patient/caregiver <strong>notified</strong> of changes in care plan/plan of care. ',
        '<strong>Nurse referral</strong> for additional education and review. ',
        '<strong>Provider conference</strong> to collaborate and coordinate patient care. ',
        '<strong>Provider notified</strong> via EHR/telephone of critical alert. ',
        '<strong>Voicemail left for return call</strong>. ',
        '<strong>Voicemail unavailable</strong>. '
      ],
      MEDICATION: [
        'Educated on the importance of medication <strong>adherence</strong> to manage diabetes. ',
        'Directed patient to visit with <strong>pharmacist</strong> to review all medications, side effects, and barriers. ',
        'Educated on strategies to improve medication adherence and explored use of <strong>reminder</strong> techniques. ',
        'Completed targeted medication <strong>review</strong> for blood glucose medications and discussed possible barriers. ',
        'Educated on blood glucose medications, including common <strong>side effects</strong> and safety.  ',
      ],
      RESOURCES: [
        'Referred to 211/DHHS. ',
        'Referred patient to Aunt Bertha findhelp.org ',
        'ADA:  "Living with Type 2 Diabetes Program" https://www.diabetes.org/diabetes/type-2/living-with-type-2-diabetes-program ',
        'Sent video link for iglucose instruction via email and/or text message.  https://www.youtube.com/watch?v=iQlQrrdCb8Q ',
      ],
      ENGAGEMENT: [
        'Program <strong>non-compliance</strong> outreach to explore missed <strong>16 days</strong> of readings per month. Called and reviewed RPM Program and biometric reading requirements for program participation to secure 16 days of readings per month to help manage blood pressure. ',
        'Educated patient on <strong>RPM Program</strong> and biometric reading requirements for program participation to secure 16 days of readings per month to help manage blood glucose. ',
        'Welcome call. Called patient to assist with successful blood glucose monitor set-up. Patient verbally agreed to RPM program and text messaging. Educated patient on proper technique to check blood glucose. Reminded patient of program requirements to check blood glucose daily. Instructed patient to expect calls for critical readings from care team. Provided care team\'s contact information. TupeloLife care team monitors biometric results, Monday through Friday between 8 AM and 5 PM CST for after-hour concerns please call your primary care provider or 9-1-1 as needed. ',
      ],
      READINGS: [
        'Reviewed. ',
        'Reviewed blood glucose, >325. ',
        'Reviewed blood glucose, <60. ',
      ],
    };



  defaultTextMessge = [
    'Thank you for checking your BP today.  Please check BP daily to watch for trends.  Have a great day! 469-914-0044 / 102.',
  ];

  defaultStagingPatientText = [
    'Biometric review complete, continuing to monitor.',
    'Engagement call with patient, requested daily blood pressure monitoring with the TupeloLife BLOOD PRESSURE cuff for provider trending and improved blood pressure control.',
    'Engagement call with patient, requested daily blood glucose monitoring with the TupeloLife BLOOD GLUCOSE MONITOR for provider trending and improved blood glucose control.',
    'Engagement call, left message for return call.'
  ];

  defaultStagingPatientEmail = [
    'Biometric review complete, continuing to monitor.',
    'Engagement call with patient, requested daily blood pressure monitoring with the TupeloLife BLOOD PRESSURE cuff for provider trending and improved blood pressure control.',
    'Engagement call with patient, requested daily blood glucose monitoring with the TupeloLife BLOOD GLUCOSE MONITOR for provider trending and improved blood glucose control.',
    'Engagement call, left message for return call.'
  ];

  public activeBlockObserver = new Subject<string>();
  public loggedTimeObserver = new Subject<{duration: number; care_type: string}>();

  setActiveBlockObserver(val): void {
    this.activeBlockObserver.next(val);
  }

  setLogTimeObserver(val): void {
    this.loggedTimeObserver.next(val);
  }

  refreshAlert: boolean = false;
  refreshRequest: boolean = false;
  refreshWaiting: boolean = false;
  refreshTasks: boolean = false;

  checkNewAlert(blockname): void {
    if (blockname === 'alerts') {
      this.refreshAlert = true;
      setTimeout(() => { this.refreshAlert = false; }, 3000);
    }
    else if (blockname === 'requests') {
      this.refreshRequest = true;
      setTimeout(() => { this.refreshRequest = false; }, 3000);
    }
    else if (blockname === 'waiting-room') {
      this.refreshWaiting = true;
      setTimeout(() => { this.refreshWaiting = false; }, 3000);
    } else if (blockname === 'tasks') {
      this.refreshTasks = true;
      setTimeout(() => { this.refreshTasks = false; }, 3000);
    }
  }




}
