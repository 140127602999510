import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { API_ENDPOINTS } from '../api-endpoints';
import { Call, Device } from '@twilio/voice-sdk';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {

  private apiEndpoints = API_ENDPOINTS;

  public twilioToken: string;
  public twilioClient: Device;
  public call: Call;
  public callStatus = new Subject<string>(); // connecting, ringing, in progress, ended, disconnected

  constructor(
    private http: HttpClient
  ) { }

  getToken(identity: any): Observable<any> {
    return this.http.get(this.apiEndpoints.provider.twilio.voice.token, {
      params: {identity}
    });
  }

  setToken(token) {
    this.twilioToken = token;
  }

  createClient() {
    this.twilioClient = new Device(this.twilioToken);
  }

  async connectCall(data) {
    this.call = await this.twilioClient.connect({
      params: data
    });

    this.call.on('cancel', () => {
      this.callStatus.next('cancel');
      // console.log('The call was cancelled.');
    });
    this.call.on('disconnect', () => {
      this.callStatus.next('disconnect');
      // console.log('The call was disconnect.');
    });
    this.call.on('reject', () => {
      this.callStatus.next('reject');
      // console.log('The call was rejected.');
    });
    this.call.on('error', () => {
      this.callStatus.next('error');
      // console.log('an error occured.');
    });
  }

  disconnectCall() {
    this.twilioClient.disconnectAll();
  }

  isMuted() {
    return this.call.isMuted();
  }

  mute(setMute: boolean) {
    this.call.mute(setMute);
  }

}
