import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private apiEndpoints = API_ENDPOINTS;

  constructor(
    private http: HttpClient
  ) { }

  create(data): Observable<any> {
    return this.http.post(this.apiEndpoints.provider.tasks.create, data);
  }

  update(id, data): Observable<any> {
    return this.http.put<any>(this.apiEndpoints.provider.tasks.update.replace('{id}', id), []);
  }

  list(pageIndex, pageSize, sortField, sortOrder, status): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('taskStatus', `${status}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);

    return this.http.get(this.apiEndpoints.provider.tasks.assigned_tasks, { params });
  }

  delete(taskId): Observable<any> {
    return this.http.delete(this.apiEndpoints.provider.tasks.delete.replace('{id}', taskId));
  }
}
