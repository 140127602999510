import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS } from '../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  apiEndpoint = API_ENDPOINTS;

  // list(data): Observable<any> {
  //   return this.http.post(this.apiEndpoint.provider.video_call.incoming_request, data);
  // }

  list(pageIndex, pageSize, sortField, sortOrder, filters, callStatus): Observable<any> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('call_status', `${callStatus}`);

    if (sortField !== null) params = params.append('sortField', `${sortField}`);
    if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter.value.forEach(value => params = params.append(filter.key, value));
      });
    }

    return this.http.get(this.apiEndpoint.provider.video_call.index, { params });
  }

  // sceduledList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
  //   let params = new HttpParams()
  //     .append('page', `${pageIndex}`)
  //     .append('pageSize', `${pageSize}`)
  //     .append('call_status', 'accepted');

  //   if (sortField !== null) params = params.append('sortField', `${sortField}`);
  //   if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
  //   if (filters.length > 0) {
  //     filters.forEach(filter => {
  //       filter.value.forEach(value => params = params.append(filter.key, value));
  //     });
  //   }

  //   return this.http.get(this.apiEndpoint.provider.video_call.index, { params });
  // }

  // declinedList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
  //   let params = new HttpParams()
  //     .append('page', `${pageIndex}`)
  //     .append('pageSize', `${pageSize}`)
  //     .append('call_status', 'declined');

  //   if (sortField !== null) params = params.append('sortField', `${sortField}`);
  //   if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
  //   if (filters.length > 0) {
  //     filters.forEach(filter => {
  //       filter.value.forEach(value => params = params.append(filter.key, value));
  //     });
  //   }

  //   return this.http.get(this.apiEndpoint.provider.video_call.index, { params });
  // }

  // waitingRoomList(pageIndex, pageSize, sortField, sortOrder, filters): Observable<any> {
  //   let params = new HttpParams()
  //     .append('page', `${pageIndex}`)
  //     .append('pageSize', `${pageSize}`)
  //     .append('call_status', 'room');

  //   if (sortField !== null) params = params.append('sortField', `${sortField}`);
  //   if (sortOrder !== null) params = params.append('sortOrder', `${sortOrder}`);
  //   if (filters.length > 0) {
  //     filters.forEach(filter => {
  //       filter.value.forEach(value => params = params.append(filter.key, value));
  //     });
  //   }

  //   return this.http.get(this.apiEndpoint.provider.video_call.index, { params });
  // }

  update(id, data): Observable<any> {
    return this.http.put<any>(this.apiEndpoint.provider.video_call.update.replace('{id}', id), data);
  }

  store(data): Observable<any> {
    return this.http.post(this.apiEndpoint.provider.video_call.store, data);
  }
  updateSeen(data): Observable<any> {
    return this.http.post(this.apiEndpoint.provider.video_call.updateSeen, data);
  }

  constructor(private http: HttpClient) { }
}
