export const PREFERRED_LANGUAGES = [
    { name: 'en', label: 'English' },
    { name: 'es-ES', label: 'Spanish(Spain)' },
    { name: 'ar', label: 'Arabic' },
    { name: 'bn', label: 'Bengali' },
    { name: 'zh', label: 'Chinese' },
    { name: 'zh-CN', label: 'Chinese (China)' },
    { name: 'zh-HK', label: 'Chinese (Hong Kong)' },
    { name: 'zh-SG', label: 'Chinese (Singapore)' },
    { name: 'zh-TW', label: 'Chinese (Taiwan)' },
    { name: 'hr', label: 'Croatian' },
    { name: 'cs', label: 'Czech' },
    { name: 'da', label: 'Danish' },
    { name: 'nl', label: 'Dutch' },
    { name: 'nl-BE', label: 'Dutch (Belgium)' },
    { name: 'nl-NL', label: 'Dutch (Netherlands)' },
    { name: 'en-AU', label: 'English (Australia)' },
    { name: 'en-CA', label: 'English (Canada)' },
    { name: 'en-GB', label: 'English (Great Britain)' },
    { name: 'en-IN', label: 'English (India)' },
    { name: 'en-NZ', label: 'English (New Zeland)' },
    { name: 'en-SG', label: 'English (Singapore)' },
    { name: 'en-US', label: 'English (United States)' },
    { name: 'fi', label: 'Finnish' },
    { name: 'fr', label: 'French' },
    { name: 'fr-BE', label: 'French (Belgium)' },
    { name: 'fr-FR', label: 'French (France)' },
    { name: 'fr-CH', label: 'French (Switzerland)' },
    { name: 'fy', label: 'Frysian' },
    { name: 'fy-NL', label: 'Frysian (Netherlands)' },
    { name: 'de', label: 'German' },
    { name: 'de-AT', label: 'German (Austria)' },
    { name: 'de-DE', label: 'German (Germany)' },
    { name: 'de-CH', label: 'German (Switzerland)' },
    { name: 'el', label: 'Greek' },
    { name: 'gu', label: 'Gujarati' },
    { name: 'hi', label: 'Hindi' },
    { name: 'it', label: 'Italian' },
    { name: 'it-IT', label: 'Italian (Italy)' },
    { name: 'it-CH', label: 'Italian (Switzerland)' },
    { name: 'ja', label: 'Japanese' },
    { name: 'ko', label: 'Korean' },
    { name: 'no', label: 'Norwegian' },
    { name: 'no-NO', label: 'Norwegian (Norway)' },
    { name: 'pl', label: 'Polish' },
    { name: 'pt', label: 'Portuguese' },
    { name: 'pt-BR', label: 'Portuguese (Brazil)' },
    { name: 'pa', label: 'Punjabi' },
    { name: 'ru', label: 'Russian' },
    { name: 'ru-RU', label: 'Russian (Russia)' },
    { name: 'sr', label: 'Serbian' },
    { name: 'sr-RS', label: 'Serbian (Serbia)' },
    { name: 'es', label: 'Spanish' },
    { name: 'es-AR', label: 'Spanish (Argentina)' },
    { name: 'es-UY', label: 'Spanish (Uruguay)' },
    { name: 'sv', label: 'Swedish' },
    { name: 'sv-SE', label: 'Swedish (Sweden)' },
    { name: 'te', label: 'Telegu' }
  ]
