import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListenerService {

  public readingTypeObserve = new Subject<any>();

  public alertsObserve = new Subject<any>();

  public transactionIdObserve = new Subject<any>();

  public floatingCallObserve = new Subject<any>();
  public muteCallObserve = new Subject<any>();

  public chatUserId = new Subject<any>();
  public alertUserId = new Subject<any>();
  public scrollDown = new Subject<any>();

  public pendingChat = new Subject<any>();

  public messageSeen = new Subject<any>();
  public alertUserIdCount = new Subject<any>();
  

  fireReadingType(r): void {
    this.readingTypeObserve.next(r);
  }

  fireAlerts(r): void {
    this.alertsObserve.next(r);
  }

  fireTransactionId(r): void {
    this.transactionIdObserve.next(r);
  }

  showFloatingCall(r): void{
    this.floatingCallObserve.next(r);
  }

  patientDetailsMuteCall(r): void{
    this.muteCallObserve.next(r);
  }

  userPatientChatUpdate(r): void{
    this.chatUserId.next(r);
  }

  scrollDownLive(r): void{
    this.scrollDown.next(r);
  }

  initiatePendingChat(r): void{
    this.pendingChat.next(r);
  }

  seenMessage(r): void{
    this.messageSeen.next(r);
  }

  updateAlertCount(r): void{
    this.alertUserId.next(r);
  }
  updatePatientAlertCount(r): void{
    this.alertUserIdCount.next(r);
  }

  


}
