import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseCustomAlert'
})
export class ParseCustomAlertPipe implements PipeTransform {

  private data;

  transform(value: Array<any>, ...args: unknown[]): string {
    this.data = value;

    if (value['vital'] === 'bp' ) {
      return this.getBpConditionString();
    }
    if (value['vital'] === 'bg' ) {
      return this.getBgConditionString();
    }
    if (value['vital'] === 'hr' ) {
      return this.getHrConditionString();
    }
    if (value['vital'] === 'wt' ) {
      return this.getWtConditionString();
    }
    if (value['vital'] === 'temp' ) {
      return this.getTempConditionString();
    }
    if (value['vital'] === 'spo2' ) {
      return this.getSpo2ConditionString();
    }
    return '';
  }

  private getBpConditionString() {
    const sysOperator = this.data['bloodPressure']['sysOperator'];
    const sysValue1 = this.data['bloodPressure']['sysValue'];
    const sysValue2 = this.data['bloodPressure']['sysValue2'];
    
    const diaOperator = this.data['bloodPressure']['diaOperator'];
    const diaValue1 = this.data['bloodPressure']['diaValue'];
    const diaValue2 = this.data['bloodPressure']['diaValue2'];
    
    const sysString = (this.data['bloodPressure']['sysEnabled'] === true) ? this.prepareString(sysOperator, sysValue1, sysValue2) : '';
    const diaString = (this.data['bloodPressure']['diaEnabled'] === true) ? this.prepareString(diaOperator, diaValue1, diaValue2) : '';

    if (this.data['bloodPressure']['sysEnabled'] === true && this.data['bloodPressure']['diaEnabled'] === true) {
      return 'Systolic ' + sysString + ' ' + this.data['bloodPressure']['operator'] + ' Diastolic ' + diaString;
    } else {
      if (this.data['bloodPressure']['sysEnabled'] === true)
        return 'Systolic ' + sysString;
      if (this.data['bloodPressure']['diaEnabled'] === true)
        return 'Diastolic ' + diaString;
    }
  }

  private getBgConditionString() {
    const operator = this.data['bloodGlucose']['bgOperator'];
    const value1 = this.data['bloodGlucose']['bgValue'];
    const value2 = this.data['bloodGlucose']['bgValue2'];

    return this.prepareString(operator, value1, value2);
  }

  private getHrConditionString() {
    const operator = this.data['heartRate']['hrOperator'];
    const value1 = this.data['heartRate']['hrValue'];
    const value2 = this.data['heartRate']['hrValue2'];

    return this.prepareString(operator, value1, value2);
  }

  private getWtConditionString() {
    const operator = this.data['weight']['wtOperator'];
    const value1 = this.data['weight']['wtValue'];
    const value2 = this.data['weight']['wtValue2'];

    return this.prepareString(operator, value1, value2);
  }

  private getTempConditionString() {
    const operator = this.data['temperature']['tempOperator'];
    const value1 = this.data['temperature']['tempValue'];
    const value2 = this.data['temperature']['tempValue2'];

    return this.prepareString(operator, value1, value2);
  }

  private getSpo2ConditionString() {
    const operator = this.data['spO2']['spo2Operator'];
    const value1 = this.data['spO2']['spo2Value'];
    const value2 = this.data['spO2']['spo2Value2'];

    return this.prepareString(operator, value1, value2);
  }

  private prepareString(operator, value1, value2): string {
    if ((operator).toUpperCase() === 'RANGE') {
      return 'In Range ' + ' ' + value1 + ' To ' + + value2;
    } else {
      return operator + ' ' + value1;
    }
  }

}
