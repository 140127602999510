import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'medicaidstatus'
})
export class MedicaidStatusPipe implements PipeTransform {

  transform(value: Date): String {
    if (value) {
      let status = 'valid';
      const endDate = new Date(value);
      const curDate = new Date();
      const ms = endDate.getTime() - curDate.getTime();
      const noOfDays = Math.floor(ms / (1000 * 3600 * 24));
      if (noOfDays < 0 ) {
        status = 'expired';
      } else if (noOfDays >= 0 && noOfDays <= 15) {
        status =  'expiring';
      }
      return status;
    }
    return 'pending';
  }

}
